<template>
  <div>
    <p class="title">本科选科设置 <span class="remakrs">可在此勾选并确定所在高中开设的选科组合</span></p>
    <div :class="{ act: userSubjectCombine.indexOf(item.id) > -1 ? true : false, subjectCard: true }"
      v-for="(item, i) in   subjectCombine  " :key="i" @click="choseSubject(item.id)">
      {{ item.name }}
    </div>

    <div class="choseBtn">
      <el-button @click="saveSubjectCombine" class="btn1">确定组合</el-button>
      <el-button @click="resetting">重置组合</el-button>
    </div>

    <p class="title">我的成绩 <span class="remakrs">可在此录入个人的成绩，系统按照你的成绩推荐一个选科组合</span></p>

    <score-table></score-table>

    <p class="title">我的测评 <span class="remakrs">可在此进行大学专业选择测评，根据测评结果将各个科目兴趣潜在程度展示如下</span></p>
    <assess></assess>

    

    <p class="title">我的选科 </p>
    <choose-specialty></choose-specialty>

  </div>
</template>

<script>
import scoreTable from './score'
import assess from './assess'
import ChooseSpecialty from './choose-specialty'
export default {
  name: '',
  data() {
    return {
      subjectCombine: [],
      userSubjectCombine: [1, 2, 3]
    };
  },
  components: {
    scoreTable, assess, ChooseSpecialty
  },

  created() {
    this.$emit('Nav', 3)
    this.getSubectList()
    this.getUserSubjectCombine()
  },
  methods: {
    getSubectList() {
      this.$fecth.post("xuanke/x_specialty/getSubjectCombine").then((res) => {
        this.subjectCombine = res
      });
    },
    getUserSubjectCombine() {
      this.$fecth.post("xuanke/x_specialty/getUserSubjectCombine").then((res) => {
        if (res) {
          this.userSubjectCombine = res.subject_combine_id
        }
      });
    },

    saveSubjectCombine() {
      let data = {
        userSubjectCombine: this.userSubjectCombine
      }
      this.$fecth.post("xuanke/x_specialty/saveSubjectCombine", data).then((res) => {
        console.log(res)
      });
      this.$notify({
        offset: 100,
        showClose: true,
        message: "保存成功",
        type: "success",
      });
    },
    resetting() {
      this.userSubjectCombine = []
    },
    choseSubject(i) {
      let index = this.userSubjectCombine.indexOf(i)
      console.log(index)
      if (index > -1) {
        this.userSubjectCombine.splice(index, 1)
      } else {
        this.userSubjectCombine.push(i)
      }
    }
  },
};
</script>

<style scoped lang='less'>
.title {
  margin-top: 30px;
}

.remakrs {
  font-size: 12px;
  color: #929292;
  margin-left: 10px;
}

.subjectCard {
  display: inline-block;
  background: #f7f7f9;
  padding: 18px 58px;
  margin-top: 20px;
  margin-right: 40px;
  border-radius: 5px;
}

.subjectCard:nth-child(7) {
  margin-right: 0;
}

.subjectCard:nth-child(13) {
  margin-right: 0;
}

.act {
  padding: 16px 56px;
  border: #ff8641 2px solid;
  background: #fef9f5;
}

.choseBtn {
  height: 40px;
  margin-top: 30px;
  padding-right: 40px;
  text-align: right;
}

.btn1 {
  background: #de844f;
  color: white;
}
</style>
