<template>
  <div>
    <div class="assess">
      <div class="title">我真正喜欢的科目是什么</div>
      <div v-if="tableData" class="table">
        <div v-for="(item, keys) in tableData" :key="keys">
          <div style="margin-top: 10px;">
            <div style="display: inline-block;margin-right: 30px; width: 12%">
              {{ item.Subject }} <span style="float: right;">{{ Number(item.Points) }}</span>
            </div>
            <div style="display: inline-block; width: 80%">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="Number(item.Points)"
                color="#ff8641"></el-progress>
            </div>
          </div>
        </div>
        <div class="remarks">备注:详细测评报告可在个人中心查看，测评报告是基于霍兰德、MBTI等一系列测评方式综合得出，测评本身具备一定科学参考价值，当仍需结合自身实际情况确定相关兴趣爱好。</div>
      </div>
      <div v-else class="table">
        <div class="remarks">暂无测评，请前去测评</div>
      </div>
    </div>
    <div class="choseBtn">
      <el-button class="btn2" v-if="tableData"><router-link to="/user/testReport">个人中心</router-link></el-button>

      <el-button class="btn2" v-else> <router-link to="/user/testReport">开始测评</router-link> </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      tableData: null
    };
  },
  
  created() {
    this.getAssess();
  },
  methods: {
    getAssess() {
      this.$fecth.post("xuanke/x_user_assess/getUserLikeSubject").then((res) => {
        if (res) {
          this.tableData = res
        }
      });
    }
  },
};
</script>

<style scoped lang='less'>
.assess {
  padding: 20px;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: #ff8641 1px solid;

  .title {
    text-align: center;
  }

  .table {
    padding: 20px 40px;

    .remarks {
      text-align: center;
      padding: 20px 0 0 0;
      color: gray;
    }
  }

}

.choseBtn {
  height: 40px;
  margin-top: 30px;
  // padding-right: 40px;

  text-align: right;

  .btn2 {
    border: 1px solid #ff8641;
  }
}
</style>
