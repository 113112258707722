<template>
  <div>

    <div class="score">
      <div v-if="zhongkao_scroe">
        <div class="score_title">中考成绩</div>
        语文：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['chinese']"> </el-input>
        数学：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['math']"></el-input>
        英语：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['englist']"></el-input>
        物理：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['physics']"></el-input>
        化学：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['chemistry']"></el-input>
        生物：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['organism']"></el-input>
        历史：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['history']"></el-input>
        政治：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['politics']"></el-input>
        地理：<el-input style="width: 70px;" size="mini" placeholder="" v-model="zhongkao_scroe['geography']"></el-input>
      </div>
      <div class="score_title">
        最近成绩
      </div>

      <div v-if="last_score">
        <div class="score_title"></div>
        语文：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['chinese']"> </el-input>
        数学：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['math']"></el-input>
        英语：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['englist']"></el-input>
        物理：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['physics']"></el-input>
        化学：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['chemistry']"></el-input>
        生物：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['organism']"></el-input>
        历史：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['history']"></el-input>
        政治：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['politics']"></el-input>
        地理：<el-input style="width: 70px;" size="mini" placeholder="" v-model="last_score['geography']"></el-input>
      </div>
    </div>

    <div class="re">
      推荐组合： <span style="color: rgb(255, 134, 65);">{{ beseSubjet() }}</span>
      <span class="remarks">
        备注:确定选科组合需要综合多方面因素，成绩只是其中一个参考纬度，系统推荐仅供参考！
      </span>
      <el-button style="float: right; border: 1px solid #ff8641; color: black;" @click="save()">保存成绩</el-button>
    </div>




  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      zhongkao_scroe: [],
      last_score: [],

    };
  },
  computed: {
    beseSubjet() {
      return function () {
        // physics 物理
        let lishi = (Number(this.zhongkao_scroe['history']) + Number(this.last_score['history']))
        let wuli = (Number(this.zhongkao_scroe['physics']) + Number(this.last_score['physics']))
        const chemistry = (Number(this.zhongkao_scroe['chemistry']) + Number(this.last_score['chemistry'])) + 0.1 //化学
        const organism = (Number(this.zhongkao_scroe['organism']) + Number(this.last_score['organism'])) + 0.2 //生物
        const politics = (Number(this.zhongkao_scroe['physics']) + Number(this.last_score['politics'])) + 0.3 //政治
        const geography = (Number(this.zhongkao_scroe['geography']) + Number(this.last_score['geography'])) + 0.4 //地理

        let arr = [chemistry, organism, politics, geography]

        arr.sort(function (a, b) {
          return b - a; //降序排序
          // return a - b; //升序排序
        });

        let str = ''

        arr.forEach(item => {
          switch (item) {
            case chemistry:
              str = str + '化'
              break;
            case organism:
              str = str + '生'
              break;
            case politics:
              str = str + '政'
              break;
            case geography:
              str = str + '地'
              break;
          }
        })

        str = str.slice(0, 2)

        if (lishi > wuli) {
          return '历' + str
        } else {
          return '物' + str
        }
      }
    },
  },
  created() {
    this.getUserScore()
  },

  methods: {
    getUserScore() {
      this.$fecth.post("xuanke/user_score/getUserScore").then((res) => {
        this.zhongkao_scroe = res[0]
        this.last_score = res[1]
      });
    },
    save() {
      let data = {
        zhongkao_scroe: this.zhongkao_scroe,
        last_score: this.last_score
      }

      this.$fecth.post("xuanke/user_score/save", data).then((res) => {
        console.log(res)
      });
    }
  },
};
</script>

<style scoped lang='less'>
.score {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: #ff8641 1px solid;

  .score_title {
    margin: 20px 0;
  }
}

.re {
  padding: 3px 0 3px;
  font-size: 14px;
  margin-bottom: 40px;
}

.remarks {
  color: #929292;
  font-size: 12px;

  margin-left: 30px;
}
</style>
