<template>
  <div>
    <div class="table">

      <div v-if="tableData != [] || tableData != null">
        我的组合：
        <el-dropdown @command="editCombine">
          <span class="el-dropdown-link" style="font-size: 16px;color: #ff8641;">
            {{ CombineData.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, key) in tableData" :key="key" :command="item">
              {{ item.name
              }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span style="font-size: 12px;color: gray;">该组合为最终选科组合，系统按此组合进行志愿填报推荐</span>
        <el-divider></el-divider>
        意向院校：<span style="color: #ff8641;margin-right: 10px;" v-if="CombineData.college_list">{{
          CombineData.college_list.length }}</span> <span class="item" v-for="(item) in CombineData.college_list"
          :key="item">{{ item }}</span>
        <el-divider></el-divider>
        意向专业(大类)：<span style="color: #ff8641;margin-right: 10px;" v-if="CombineData.category_list">{{
          CombineData.category_list.length }}</span> <span class="item" v-for="(item, key) in CombineData.category_list"
          :key="key">{{ item.specialty_name
          }}</span>
      </div>
    </div>
    <!-- <div class="choseBtn">
      <el-button class="btn2">确定选科</el-button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      tableData: null,
      CombineData: {
        name: ''
      }
    };
  },

  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.$fecth.post("xuanke/user_combine_list/getUserAllCombineTable").then((res) => {
        if (res.length != 0) {
          this.tableData = res
          this.CombineData = res[0]
        }
      });
    },
    editCombine(item) {
      this.CombineData = item
    }
  },
};
</script>

<style scoped lang='less'>
.table {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: #ff8641 1px solid;

  .item {
    margin-right: 40px;
    line-height: 30px;
  }
}

.choseBtn {
  height: 40px;
  margin-top: 30px;
  padding-right: 40px;

  text-align: right;

  .btn2 {
    border: 1px solid #ff8641;
  }
}
</style>
